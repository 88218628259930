import React, { useEffect, useRef } from 'react'

const ImageResizer = ({ imageUrl, maxWidth = 4096, maxHeight, onResizeComplete }) => {
  const canvasRef = useRef(null)

  // Function to check maximum texture size
  const getMaxTextureSize = () => {
    try {
      const canvas = document.createElement('canvas')
      const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl')
      return gl ? gl.getParameter(gl.MAX_TEXTURE_SIZE) : 0
    } catch (error) {
      console.error('Unable to detect WebGL support:', error)
      return 0
    }
  }

  useEffect(() => {
    if (!imageUrl) return

    const maxTextureSize = getMaxTextureSize()

    if (maxTextureSize < 4096) {
      // If the device doesn't support high resolution, return the original image URL
      // console.warn(Device supports up to ${maxTextureSize}px, returning original image.)
      if (onResizeComplete) onResizeComplete(imageUrl)
      return
    }

    const img = new Image()
    img.crossOrigin = 'anonymous' // Support cross-origin loading
    img.src = imageUrl

    img.onload = () => {
      // Calculate the target dimensions
      let width = img.width
      let height = img.height

      if (maxWidth && width > maxWidth) {
        height = (height * maxWidth) / width
        width = maxWidth
      }

      if (maxHeight && height > maxHeight) {
        width = (width * maxHeight) / height
        height = maxHeight
      }

      // Draw and resize the image using canvas
      const canvas = canvasRef.current
      if (canvas) {
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        // Get the resized image as a data URL
        const dataUrl = canvas.toDataURL()

        // Call the callback function with the resized image
        if (onResizeComplete) {
          onResizeComplete(dataUrl)
        }
      }
    }

    img.onerror = () => {
      console.error('Failed to load image. Please check the URL.')
    }
  }, [imageUrl, maxWidth, maxHeight, onResizeComplete])

  return <canvas ref={canvasRef} style={{ display: 'none' }} />
}

export default ImageResizer
